/* globals $ */

(function () {
  'use strict'
  $(document).ready(function () {
    console.log('ready!')
    $('#hamburger').on('click', function (e) {
      $('.mobile-menu').slideToggle('fast')
      e.preventDefault()
    })
  })
})()
